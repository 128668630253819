import logo from './logo.svg';
import './App.css';
import Overview from './components/OverviewPage';
import { ThemeProvider, createTheme, CssBaseline, Switch } from "@mui/material";

import { useState } from 'react';
function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const theme = createTheme({
    palette: { mode: isDarkMode ? "dark" : "light" },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <div className="App">
  {/*     <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
        {/* Dark Mode Toggle */}
        <div className="flex items-center space-x-2">
  <span>{isDarkMode ? 'Dark Mode' : 'Light Mode'}</span>
  <button className={`toggle-button ${isDarkMode ? 'dark' : 'light'}`} onClick={toggleDarkMode}>
    {isDarkMode ? '🌙' : '☀️'}
  </button>
</div>
      <Overview isDarkMode={isDarkMode} />
      
    </div>
    </ThemeProvider>
  );
}

export default App;

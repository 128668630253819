import React, { useEffect, useState, useRef } from 'react';
import './Overview.css';

const paragraphs = [
  ["Unlock your knowledge", "Boost productivity with measurable time savings"],
  ["Tailored to your team’s needs", "More efficient than standard GPTs"],
  ["Discover new capabilities", "Transform your workflow effectively"],
];

const Overview = ({ isDarkMode }) => {
  const [currentParagraphs, setCurrentParagraphs] = useState(paragraphs[0]);
  const [index, setIndex] = useState(0);
  const [currentSection, setCurrentSection] = useState(1); // Track the current section
  const nextSectionRef = useRef(null); // Reference for the next section
  const firstSectionRef = useRef(null); // Reference for the first section
  const thirdSectionRef = useRef(null); // Reference for the third section
  const fourthSectionRef = useRef(null); // Reference for the fourth section
  const fifthSectionRef = useRef(null); // Reference for the fifth section

  const [currentTextIndex, setCurrentTextIndex] = useState(0); // Track the current text being typed

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % paragraphs.length);
      setCurrentParagraphs(paragraphs[(index + 1) % paragraphs.length]);
      setCurrentTextIndex(0); // Reset text index when paragraphs change
    }, 10000);

    return () => clearInterval(intervalId);
  }, [index]);
  useEffect(() => {
    if (currentTextIndex < currentParagraphs.length - 1) {
      const timeoutId = setTimeout(() => {
        setCurrentTextIndex(currentTextIndex + 1);
      }, 5500); // Adjust the delay to match the typing animation duration
      return () => clearTimeout(timeoutId);
    }
  }, [currentTextIndex, currentParagraphs]);
  const changeParagraphs = () => {
    setIndex((index + 1) % paragraphs.length);
    setCurrentParagraphs(paragraphs[(index + 1) % paragraphs.length]);
    setCurrentTextIndex(0); // Reset text index when paragraphs change

  };

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Function to scroll back to the first section
  const scrollToFirstSection = () => {
    if (firstSectionRef.current) {
      firstSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToThirdSection = () => {
    if (thirdSectionRef.current) {
      thirdSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToFourthSection = () => {
    if (fourthSectionRef.current) {
      fourthSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToFifthSection = () => {
    if (fifthSectionRef.current) {
      fifthSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // Intersection Observer to detect which section is currently in view
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Update current section based on which section is visible
          setCurrentSection(entry.target.dataset.section);
        }
      });
    }, {
      threshold: 0.5 // Trigger when at least 50% of the section is visible
    });

    // Observe both sections
    const firstSection = firstSectionRef.current;
    const nextSection = nextSectionRef.current;
    const thirdSection = thirdSectionRef.current;
    const fourthSection = fourthSectionRef.current;
    const fifthSection = fifthSectionRef.current;
    if (firstSection) observer.observe(firstSection);
    if (nextSection) observer.observe(nextSection);
  if (thirdSection) observer.observe(thirdSection);
  if (fourthSection) observer.observe(fourthSection);
  if (fifthSection) observer.observe(fifthSection);
    return () => {
      if (firstSection) observer.unobserve(firstSection);
      if (nextSection) observer.unobserve(nextSection);
      if (thirdSection) observer.unobserve(thirdSection);
      if (fourthSection) observer.unobserve(fourthSection);
      if (fifthSection) observer.unobserve(fifthSection);
    };
  }, []);

  return (
    <>
    
      {/* First Section */}
      <div ref={firstSectionRef} className={`overview-container ${isDarkMode ? 'dark-mode' : ''}`} data-section="1">
        {/* Conditionally switch image based on dark mode */}
        <img
        
          src={isDarkMode ? '/image-3.png' : '/image-1.png' }
          alt="Glasses Icon"
          className="glasses-image"
        />
        <div className="text-wrapper">
          {currentParagraphs.map((text, i) => (
        <h1 key={i} className={`animated-text ${i === currentTextIndex ? 'typewriter' : ''}`} onClick={changeParagraphs}>
        {text}
      </h1>
          ))}
        </div>
            {/* Bounce animation element */}
            

            <svg xmlns="http://www.w3.org/2000/svg"className="animate-bounce arrow-icon" viewBox="0 0 384 512" width="50" height="50">
  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
</svg>
<br />
        <button onClick={() => window.location.href = 'https://beta.icosa.tetra-ai.fr/ui'}  className="start-button">Start now</button>
      </div>
      <hr className="custom-hr" />
      {/* Next Section */}
      <div className={`next-section ${isDarkMode ? 'dark-mode' : ''}`} ref={nextSectionRef} data-section="2">
        <h1><span className="green-text">Measurable Time Savings</span></h1>
        <div className="typewriter"><p>Instantly retrieve accurate information from your internal documents.</p></div>
        <p className="sub-text">Boost your team's efficiency with instant access to
           your company's internal knowledge. Our AI-powered chatbot is designed to transform the way your
            employees find and use information.</p>
      </div>


      <hr className="custom-hr" />

      
   {/* Third Section - Enhanced Productivity */}
   <div className={`third-section ${isDarkMode ? 'dark-mode' : ''}`} ref={thirdSectionRef} data-section="3">
        <h1 className="fourth-section-title">Enhanced Productivity</h1>
        <div className="third-section-subtitle">Reduce time spent searching for information and focus on what matters most.</div>
        <p className="sub-text">
          With advanced natural language understanding tailored to your business, your team can
          quickly access the right information — whether it’s policies, procedures, product details, or
          any other critical content.
        </p>
        <h2 className="third-section-content">
          This ensures faster decision-making and a more streamlined workflow.
        </h2>
      </div>













      <hr className="custom-hr" />
      {/* Fourth Section - Customized for Your Needs */}
      <div className={`fourth-section ${isDarkMode ? 'dark-mode' : ''}`} ref={fourthSectionRef} data-section="4">
        <h1 className="fourth-section-title">Customized for Your Needs</h1>
        <p className="fourth-section-subtitle">
          More powerful than generic AI models, with answers that are specifically relevant to your business context.
        </p>
        <p className="fourth-section-content">
          Unlike standard GPT models, our solution delivers precise, context-aware answers from your internal documentation,
          significantly reducing search time and enhancing productivity.
        </p>
      </div>
      <hr className="custom-hr" />
      {/* Fifth Section - Seamless Integration */}
      <div className={`fifth-section ${isDarkMode ? 'dark-mode' : ''}`} ref={fifthSectionRef} data-section="5">
        <h1 className="fifth-section-title" >Seamless Integration</h1>
        <p className="fifth-section-subtitle">Easy to implement with existing systems, ensuring a smooth transition and immediate value.</p>
      </div>
      {/* Fixed Arrow Buttons */}
      {currentSection === "1" && (
        <button className="arrow-button" onClick={scrollToFifthSection}>
          &#8595; {/* Down arrow */}
        </button>
      )}
      {currentSection === "2" && (
        <button className="arrow-button" onClick={scrollToFirstSection}>
          &#8593; {/* Up arrow */}
        </button>
      )}
        {currentSection === "3" && (
        <button className="arrow-button" onClick={scrollToNextSection}>
          &#8593;
        </button>
      )}
      {currentSection === "4" && (
        <button className="arrow-button" onClick={scrollToThirdSection}>
          &#8593;
        </button>
      )}
           {currentSection === "5" && (
        <button className="arrow-button" onClick={scrollToFirstSection}>
          &#8593;
        </button>
      )}
    </>
  );
};

export default Overview;
